import { COMPOSE_AB_SCRIPT } from '@/services/Configuration'
import Script from 'next/script'
import React from 'react'

const ComposeABScript: React.FC = () => (
  <Script
    strategy="afterInteractive"
    type="application/javascript"
    src={COMPOSE_AB_SCRIPT}
  />
)

export default ComposeABScript
